import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { ThemeProvider } from "styled-components"
import { Helmet } from "react-helmet"
import variables from "~/styles/variables"
import StylesReset from "~/styles/reset"
import StylesBase from "~/styles/base"
import Footer from "~/components/Footer"

const Layout = ({ children, location }) => {
  return (
    <ThemeProvider theme={variables}>
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <>
            <Helmet defer={false}>
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0, user-scalable=no"
              />
            </Helmet>
            <StylesReset />
            <StylesBase />
            {children}
            <Footer />
          </>
        )}
      />
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

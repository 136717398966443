const WebFont = require("webfontloader")

export const onInitialClientRender = async () => {
  WebFont.load({
    custom: {
      families: ["Lato wf:n4,i4,n7,i7", "Khmer MB wf:n7"],
      urls: ["/fonts/fonts.css"],
    },
  })
}

const colors = {
  white: "#fff",
  black: "#000",
  grey: "#404545",
}

const variables = {
  // Colors
  color: {
    ...colors,

    primary: colors.black,
    text: colors.grey,
    background: colors.white,
  },

  // Typography
  font: {
    primary: "Lato wf, sans-serif",
    secondary: "Khmer MN wf, serif",
  },
  basefontWeight: 400,
  baseFontSize: "18px",
  baseLineHeight: 1.6,

  // Layout
  containerWidth: 1040,
  wideContainerWidth: 1240,
  sidePadding: {
    phone: 25,
    tablet: 35,
    desktop: 50,
  },

  // Easing functions
  easing: {
    easeOutQuad: "cubic-bezier(0.25, 0.46, 0.45, 0.94)",
    easeInOutQuad: "cubic-bezier(0.455, 0.03, 0.515, 0.955)",
    easeOutQuart: "cubic-bezier(0.165, 0.84, 0.44, 1)",
    easeInOutQuart: "cubic-bezier(0.77, 0, 0.175, 1)",
    easeOutExpo: "cubic-bezier(0.19, 1, 0.22, 1)",
    easeInOutExpo: "cubic-bezier(1, 0, 0, 1)",
  },
}

export default variables

import { createGlobalStyle } from "styled-components"
// import { media } from "src/styles/breakpoints"

export default createGlobalStyle`
  body {
    background-color: ${(p) => p.theme.color.background};
    font-weight: ${(p) => p.theme.basefontWeight};
    font-size: ${(p) => p.theme.baseFontSize};
    line-height: ${(p) => p.theme.baseLineHeight};
    font-family: ${(p) => p.theme.font.primary};
    color: ${(p) => p.theme.color.text};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    cursor: default;
  }

  ::selection {
    background: #000;
    color: #fff;
  }
`

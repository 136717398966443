import React from "react"
import { Link as GatsbyLink } from "gatsby"

export default ({ to, children, className }) => {
  const isExternal =
    to?.includes("//") || to?.includes("mailto:") || to?.includes(".pdf")

  return isExternal ? (
    <a href={to} target="_blank" rel="noopener" className={className}>
      {children}
    </a>
  ) : (
    <GatsbyLink to={to} className={className}>
      {children}
    </GatsbyLink>
  )
}

import React from "react"
import Link from "~/components/Link"
import styled from "styled-components"
import Container from "~/components/Container"
import MailIcon from "~/assets/icons/mail.svg"
import FacebookIcon from "~/assets/icons/facebook.svg"
import TwitterIcon from "~/assets/icons/twitter.svg"
import InstagramIcon from "~/assets/icons/instagram.svg"
import indexPageData from "../../content/pages/index.json"

const navigation = [
  [
    {
      title: "Books",
      path: "/books",
    },
    {
      title: "About",
      path: "/about",
    },
    {
      title: "Stories",
      path: "/stories",
    },
    {
      title: "Donate",
      path: "/books",
    },
  ],
  [
    {
      title: "Excerpt",
      path: indexPageData.excerptFile,
    },
    {
      title: "Contact",
      path: "mailto:info@lemurwhospoke.org",
    },
  ],
]

export default () => (
  <Wrapper>
    <Container>
      <Logo to="/">The Little Lemur Who Spoke</Logo>
      <Navigation>
        {navigation.map((list, index) => (
          <NavigationList key={index}>
            {list.map(({ title, path }, index) => (
              <NavigationItem key={index}>
                <NavigationLink to={path}>{title}</NavigationLink>
              </NavigationItem>
            ))}
          </NavigationList>
        ))}
      </Navigation>
      <SocialMediaList>
        <SocialMediaItem className="email">
          <SocialMediaLink to="mailto:info@lemurwhospoke.org">
            <MailIcon />
          </SocialMediaLink>
        </SocialMediaItem>
        {/*<SocialMediaItem className="facebook">
          <SocialMediaLink to="https://facebook.com/LemurWhoSpoke">
            <FacebookIcon />
          </SocialMediaLink>
        </SocialMediaItem>
        <SocialMediaItem className="instagram">
          <SocialMediaLink to="https://www.instagram.com/lemurwhospoke">
            <InstagramIcon />
          </SocialMediaLink>
        </SocialMediaItem>
        <SocialMediaItem className="twitter">
          <SocialMediaLink to="https://twitter.com/LemurWhoSpoke">
            <TwitterIcon />
          </SocialMediaLink>
            </SocialMediaItem>*/}
      </SocialMediaList>
      <Copyright>
        Copyright © Alex Dunkel
        <br />
        Illustrations:{" "}
        <Link to="https://www.instagram.com/yasemin__arkun">Yasemin Arkun</Link>
        <br />
        Web Design: <Link to="http://peterarendt.co">Peter Arendt</Link>
      </Copyright>
    </Container>
  </Wrapper>
)

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 420px;
  background: url(/images/bg-footer.jpg) center center / cover no-repeat #888;
  text-align: center;
  color: #fff;
`

const Logo = styled(Link)`
  font-weight: bold;
  font-size: 22px;
  font-family: ${p => p.theme.font.secondary};
`

const Navigation = styled.nav`
  margin: 20px 0 30px;
`

const NavigationList = styled.ul`
  &:nth-of-type(n + 2) {
    margin-top: 5px;
    font-size: 14px;
  }
`

const NavigationItem = styled.li`
  display: inline-block;
  margin: 0 10px;
`

const NavigationLink = styled(Link)`
  color: rgba(255, 255, 255, 0.6);
  transition: 0.15s;

  &:hover {
    color: #fff;
  }
`

const SocialMediaList = styled.ul`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
`

const SocialMediaItem = styled.li`
  position: relative;

  &.twitter {
    top: 1px;
  }
`

const SocialMediaLink = styled(Link)`
  display: inline-block;
  padding: 10px 15px;
  color: rgba(255, 255, 255, 0.6);
  transition: 0.15s;

  svg {
    width: 20px;
    height: 20px;

    ${SocialMediaItem}.email & {
      width: 19px;
      height: 19px;
    }

    ${SocialMediaItem}.facebook & {
      width: 19px;
      height: 19px;
    }
  }

  &:hover {
    color: #fff;
  }
`

const Copyright = styled.p`
  color: rgba(255, 255, 255, 0.3);
  font-size: 15px;

  a {
    transition: 0.15s;

    &:hover {
      color: rgba(255, 255, 255, 0.6);
    }
  }
`
